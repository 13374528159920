import consumer from "../admin_channels/consumer";

consumer.subscriptions.create("ImpersonateChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    const impersonateDetails = document.getElementById("impersonate-details");
    if (impersonateDetails) {
      const userId = impersonateDetails.getAttribute("data-impersonating-id");
      const adminUserId = impersonateDetails.getAttribute("data-impersonator-id");
      const sessionExpired = data.admin_user_id == adminUserId && ((data.action == "start" && data.user_id != userId) || (data.action == "stop" && data.user_id == userId));

      if (sessionExpired) {
        // eslint-disable-next-line xss/no-mixed-html
        impersonateDetails.setAttribute("data-impersonating-id", ""); // block any double or re-impersonate attempts
        // eslint-disable-next-line xss/no-mixed-html
        const title = '<i class="fa fa-warning orange-text" aria-hidden="true"></i> Impersonate session expired';
        const content = $("#expire-impersonate").html();
        window.showDialog({ title, content });
      }
    }
  },
});
